import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OperationRentalService } from 'src/app/services/Operations/Rentals.service';
import { VendorService } from 'src/app/services/Operations/Vendor.service';
import Swal from 'sweetalert2';
import { AdminPrinterSettingService } from 'src/app/services/SystemSettings/PrinterSettings.service';
import { forkJoin, Subscription } from 'rxjs';
import { SharedDataService } from '../SharedData.service';
import { ToastrService } from 'ngx-toastr';
import { customCurrencyPipe } from '../customCurrency.pipe';
import { Location } from '@angular/common';
import { PaymentService } from 'src/app/services/Operations/paymentService.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import printJS from 'print-js';
import { CreditCardService } from 'src/app/services/SystemSettings/CreditCard.service';

@Component({
  selector: 'app-vendor-payments',
  templateUrl: './vendor-payments.component.html',
  styleUrls: ['./vendor-payments.component.scss']
})
export class VendorPaymentsComponent implements OnInit {
  @Input() vendorIdBoothTRansfer: number;       
  @Input() boothTransfer: boolean;  
  @Input() balanceDue:any;
  @Output() closePaymentComponent = new EventEmitter<void>(); 
  @Output() paymentResponse = new EventEmitter<any>();
  @Output() transactionCompleted = new EventEmitter<any>();
  creditCardTypes = [];
  vendorId;
  vendorInfo;
  vendorMarketID;
  displayContract=true;
  totalRentalCharges = 0;
  vendorName;
  paymentData;
  public loaderIcon: Promise<boolean>;
  private subscriptions: Subscription[] = [];
  marketId
  paymentForm: FormGroup;
  warnExcessPayment;
  warnZeroPayment;
  displayOtherChargeByBooth;
  calculateChangeAmount;
  keyedInTransaction = false;
  processPayment = false;
  voidPayment = false;
  verifoneId = "";
  creditCardAmount;
  DisablePayment = false;
  IsQDR = false;
  IsVendorPayment = false;
  displayAmountPaid=false;
  transactionType="CREDITSALE"
  paymentGatewayCredentials;
  processCreditCardOnline;
  applyCCCharge = false;
  thermalPrinting = false;
  actualTotalDue = 0;
  disablePayBtnAfterSubmit=false;
  @ViewChild('checkNo') checkNo: ElementRef;
  private clickTimeout: any;
  toasterConfig = {
    closeButton: true,
    timeOut: 4500,
    easing: 'ease-out',
    progressBar: true,
    positionClass: 'toast-top-right',
    tapToDismiss: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp'
  };
  paymentOnly = false;
  @ViewChild('printf') receiptIframe: ElementRef;  
  @ViewChild('printReceiptPopup', { static: true }) printReceipt: ModalDirective;
  @ViewChild('printContract') contractIframe: ElementRef;
  @ViewChild('printContractPopup', { static: true }) printContractPopup: ModalDirective;
  printReceiptPopupOpen = false;
  printContractPopupOpen = false;
  previewReceiptPopupOpen = false;
  previewContractPopupOpen = false;
  emailContract = false;
  emailReceipt = false;
  CreditCardList = [];
  allCreditCards=[];
  cashThermalCopies = 0;
  cashLaserCopies = 0;
  checkThermalCopies = 0;
  checkLaserCopies = 0
  creditThermalCopies = 0;
  creditLaserCopies = 0
  directDebitThermalCopies = 0;
  directDebitLaserCopies = 0;
  displayUFMPrinterSettings = false;

  constructor(private operationRentalService: OperationRentalService,
    private vendorService: VendorService,
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: FormBuilder,
    private adminPrinterSettingService: AdminPrinterSettingService,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService,
    private customCurrency:customCurrencyPipe,
    private paymentService: PaymentService,
    private creditcardTypeService: CreditCardService,
    private location: Location,
    private renderer: Renderer2) {
    this.paymentForm = this.formBuilder.group({
      paymentDetails: this.formBuilder.group({
        cashAmount: this.customCurrency.transform('0.00'),
        checkAmount: this.customCurrency.transform('0.00'),
        creditCardAmount: this.customCurrency.transform('0.00'),
        directDebitAmount: this.customCurrency.transform('0.00'),
        convenienceFee: this.customCurrency.transform('0.00'),
        changeAmount: this.customCurrency.transform('0.00'),
        balanceAmount: this.customCurrency.transform('0.00'),
        totalDue:this.customCurrency.transform('0.00'),
        creditCardTypes: [{ value: '1', disabled: true }],
        checkNo: [{ value: '', disabled: true }],
        displayReceipt: [false],
        printReceipt: [false],
        emailReceipt: [false],
        displayContract: [false],
        printContract: [false],
        emailContract: [false],
        PrintBillNum : [false],
        keyedInTransaction:[{ value: false, disabled: true }],
        CreditCards:[{ value: '1', disabled: true }]
      })
    });
  }

 @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['F2', 'F3', 'F4', 'F5'].includes(event.key)) {
      event.preventDefault(); 
      this.paymentForm.controls['paymentDetails'].get('cashAmount').setValue(this.customCurrency.transform("0"));
      this.paymentForm.controls['paymentDetails'].get('checkAmount').setValue(this.customCurrency.transform("0"));
      this.paymentForm.controls['paymentDetails'].get('creditCardAmount').setValue(this.customCurrency.transform("0"));
      this.paymentForm.controls['paymentDetails'].get('directDebitAmount').setValue(this.customCurrency.transform("0"));

      switch (event.key) {
        case 'F2':
          this.paymentForm.controls['paymentDetails'].get('cashAmount').setValue(this.customCurrency.transform(this.actualTotalDue.toString()));
          break;
        case 'F3':
          this.paymentForm.controls['paymentDetails'].get('checkAmount').setValue(this.customCurrency.transform(this.actualTotalDue.toString()));
          break;
        case 'F4':
          this.paymentForm.controls['paymentDetails'].get('creditCardAmount').setValue(this.customCurrency.transform(this.actualTotalDue.toString()));
          break;
        case 'F5':
          this.paymentForm.controls['paymentDetails'].get('directDebitAmount').setValue(this.customCurrency.transform(this.actualTotalDue.toString()));
          break;
      }
      this.calculateBalance();     
    }
  }
  
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.vendorId = params['vid'];
    });    
    if (this.boothTransfer) {
      this.vendorId=this.vendorIdBoothTRansfer;
    }
    this.getVendorInfobyId();
    this.getCreditCardTypes();
    this.getCCCredentials();      
    if (!this.boothTransfer) {
    this.paymentData = this.sharedDataService.paymentSharedData;
    this.totalRentalCharges = this.paymentData.RentalCharge + this.paymentData.SalesTax + this.paymentData.SecLevelTax + this.paymentData.OtherCharge;
    this.paymentOnly = this.paymentData.PaymentOnly;    
   // this.displayContract = this.paymentData.RentalMethod == "deposit" ||  this.paymentOnly == true ? false : true;
    this.sharedDataService.rentalType = this.paymentData.RentalType;
    if (this.sharedDataService.paymentSharedData.IsQDR != undefined) {
      this.IsQDR = this.sharedDataService.paymentSharedData.IsQDR;
    }   
    if (this.sharedDataService.paymentSharedData.IsVendorPayment != undefined) {
      this.IsVendorPayment = this.sharedDataService.paymentSharedData.IsVendorPayment;
    }}
  }

  setCurrencyFormat(controls) {
    const value = controls.value;
    let inputValue = value;
    if(inputValue.toString().substring(1,2) == "$")
    inputValue = `${inputValue.slice(1)}`;
    controls.setValue(inputValue);
  }

  onCheckNumKeyPress(event: KeyboardEvent) {
    const allowedChars = /[a-zA-Z0-9]/;
    const key = event.key;
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === '#') {
      return;
    }
    if (!key.match(allowedChars)) {
      event.preventDefault();
    }
  }

  setMinimum(controls) {
    const value = controls.value;
    var enteredAmount = value.toString().includes('(') && value.toString().includes(')')  ? -1 * parseFloat(value.toString().replace(/[^0-9.-]/g, '')): parseFloat(value.toString().replace(/[^0-9.-]/g, ''));
    if (!enteredAmount) {
      controls.setValue(this.customCurrency.transform("0.00"));
    }
    this.calculateBalance();
  }

  calculateBalance() {
    this.paymentForm.controls['paymentDetails'].get('checkNo').disable();
    this.paymentForm.controls['paymentDetails'].get('creditCardTypes').disable();
    this.paymentForm.controls['paymentDetails'].get('keyedInTransaction').disable();
    var cashAmount = this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, ''));
    if (!cashAmount) {
      cashAmount = 0;
      this.paymentForm.controls['paymentDetails'].get('cashAmount').setValue(this.customCurrency.transform("0"));
    }
    const checkAmount = this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const creditCardAmount = this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const directDebitAmount = this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, ''));
    this.creditCardAmount = this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const totalDue = this.actualTotalDue;
    const convenienceFee = 0;
    if (this.calculateChangeAmount) {
      var changeAmount = 0.00;
      if (cashAmount > totalDue) {
        changeAmount = cashAmount - totalDue;
      }
      this.paymentForm.controls['paymentDetails'].patchValue({ 'changeAmount': this.customCurrency.transform(changeAmount.toString()) })
    }

    if (checkAmount > 0)
      this.paymentForm.controls['paymentDetails'].get('checkNo').enable();
    else
      this.paymentForm.controls['paymentDetails'].get('checkNo').setValue('')

    if (creditCardAmount > 0)
    {
      this.paymentForm.controls['paymentDetails'].get('creditCardTypes').enable();
      this.checkPaymentType();
      if(this.applyCCCharge){
        this.paymentForm.controls['paymentDetails'].get('CreditCards').enable();
      }
    }
    else{
      this.paymentForm.controls['paymentDetails'].get('creditCardTypes').setValue('1');
      this.paymentForm.controls['paymentDetails'].get('CreditCards').disable();
    }

    var totalAmount;
    if (this.calculateChangeAmount) {
      const changeAmt = this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, ''))
      totalAmount = (cashAmount - changeAmt) + checkAmount + creditCardAmount + directDebitAmount + convenienceFee;
    }
    else {
      totalAmount = cashAmount + checkAmount + creditCardAmount + directDebitAmount + convenienceFee;
    }
    const balanceAmount = totalDue - totalAmount;

    if (balanceAmount != 0 && this.IsQDR) {
      this.DisablePayment = true;
    } else {
      this.DisablePayment = false;
    }
  
    this.paymentForm.controls['paymentDetails'].patchValue({
      totalDue: this.customCurrency.transform(totalDue.toString()),
      cashAmount: this.customCurrency.transform(cashAmount.toString()),
      creditCardAmount: this.customCurrency.transform(creditCardAmount.toString()),
      checkAmount: this.customCurrency.transform(checkAmount.toString()),
      directDebitAmount: this.customCurrency.transform(directDebitAmount.toString()),
      balanceAmount: this.customCurrency.transform(balanceAmount.toString()),
      convenienceFee: this.customCurrency.transform("0")
    })

    if(this.applyCCCharge && creditCardAmount > 0){
      this.calculateConvenienceFee();
    }
  }

  getVendorInfobyId() {
    this.vendorService.GetVendorById(this.vendorId).subscribe((response) => {
      if (response) {
        this.vendorInfo = response.body;
        this.vendorMarketID = this.vendorInfo.MarketVendorId;
        this.vendorName = this.vendorInfo.IsPublicVendor ? (this.paymentData.VendorName != undefined ? this.paymentData.VendorName.toString() : this.vendorInfo.VendorName) : this.vendorInfo.VendorName;
        this.marketId = this.vendorInfo.MarketId
        this.getPrinterAdditionalSettings();
        this.getRentalPaymentSettings();
        this.getAllCreditCardsByMarket();
        this.getCustomPrinterSettting();
        if(this.vendorInfo.IsPublicVendor && this.IsQDR){
          //this.DisablePayment = true; 
          this.toastr.info($localize`Make Full Payment`, $localize`Info`, this.toasterConfig);
        }
      }
    }, (err) => {
    });
  }

  checkPaymentType() {
    if (this.paymentForm.controls['paymentDetails'].value['creditCardTypes'] == "1") {
      this.paymentForm.controls['paymentDetails'].get('keyedInTransaction').enable();
      this.transactionType = "CREDITSALE"
    }
    else {
      this.paymentForm.controls['paymentDetails'].get('keyedInTransaction').disable();
      this.paymentForm.controls['paymentDetails'].get('keyedInTransaction').setValue(false);
      this.transactionType = "DEBITSALE"
    }

    if(this.allCreditCards.length > 0){
      var cardtype = this.creditCardTypes.filter(e=>e.CreditCardTypeId == this.paymentForm.controls['paymentDetails'].get('creditCardTypes').value)[0]?.CompanyCreditCardTypeDesc;
      this.CreditCardList = this.allCreditCards.filter(e=>e.CreditCardType ==cardtype)
    }
  }

  checkkeyedInTransaction() {
    this.keyedInTransaction = false;
    if (this.paymentForm.controls['paymentDetails'].value['keyedInTransaction'])
      this.keyedInTransaction = true;
  }

  getCreditCardTypes() {
    this.operationRentalService.getCreditCardTypes().subscribe((response) => {
      if (response) {
        //  this.creditCardTypes = response;
        this.creditCardTypes = [{
          CreditCardTypeId: "1",
          CompanyCreditCardTypeDesc: "Credit Card"
        },
        {
          CreditCardTypeId: "2",
          CompanyCreditCardTypeDesc: "Debit Card"
        }
        ]
      }
    }, (err) => {
    });
  }

  getAllCreditCardsByMarket() {    
    this.loaderIcon = Promise.resolve(true);
    this.subscriptions.push(this.creditcardTypeService.GetAllCreditCardsByMarket(this.marketId).subscribe((res: any) => {     
      this.CreditCardList = res.map((el, i) => ({
        CreditCardTypeId: el.CreditCardTypeId,
        CreditCardTypeDesc: el.CompanyCreditCardTypeDesc,
        ChargeType: el.ChargeType,
        Charge: el.Charge,
        CreditCardType: el.CardType,
        MarketId : el.MarketId
      }));
      this.allCreditCards = this.CreditCardList;
      this.paymentForm.controls['paymentDetails'].get('CreditCards').setValue(this.CreditCardList[0].CreditCardTypeId);
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    }));  
  }

  calculateConvenienceFee() {
    var convenienceFee = 0;
    var cashAmount = this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, ''));
    if (!cashAmount) {
      cashAmount = 0;
      this.paymentForm.controls['paymentDetails'].get('cashAmount').setValue(this.customCurrency.transform("0"));
    }
    const checkAmount = this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const directDebitAmount = this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, ''));
    var creditCardAmount = this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, ''));
    var totalDue = this.actualTotalDue;
     var creditcardData = this.CreditCardList.filter(e => e.CreditCardTypeId == this.paymentForm.controls['paymentDetails'].value['CreditCards']);
    if (creditcardData) {
      if (creditCardAmount > 0) {
        if (creditcardData[0].ChargeType.includes("Percentage")) {
          var convenienceFee = parseFloat((creditCardAmount * ((creditcardData[0].Charge) / 100)).toFixed(2));
        }
        else {
          convenienceFee = creditcardData[0].Charge;
        }
        totalDue += convenienceFee;
        this.creditCardAmount += convenienceFee; 
      }
    }
    var totalAmount = 0;
    if (this.calculateChangeAmount) {
      const changeAmt = this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, ''))
      totalAmount = (cashAmount - changeAmt) + checkAmount + creditCardAmount + directDebitAmount + convenienceFee;
    }
    else {
      totalAmount = cashAmount + checkAmount + creditCardAmount + directDebitAmount + convenienceFee;
    }
    const balanceAmount = totalDue - totalAmount;

    this.paymentForm.controls['paymentDetails'].patchValue({
      convenienceFee: this.customCurrency.transform(convenienceFee.toString()),
      totalDue: this.customCurrency.transform(totalDue.toString()),
      balanceAmount: this.customCurrency.transform(balanceAmount.toString())
    })
  }

  getRentalPaymentSettings() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.operationRentalService.getRentalSettings(this.marketId).subscribe((res: any) => {
      if (res) {
        this.warnExcessPayment = res.WarnExcessPayment;
        this.warnZeroPayment = res.WarnZeroPayment;
        this.displayOtherChargeByBooth = res.DisplayOtherChargesByBooth;
        this.calculateChangeAmount = res.CalculateChange;
        this.displayAmountPaid = res.DisplayAmountPaid
        this.applyCCCharge = res.ApplyCCCharge;
        if (this.boothTransfer) {
          if (this.displayAmountPaid) {
            if (this.balanceDue) {
              this.actualTotalDue = this.balanceDue
              this.paymentForm.controls['paymentDetails'].patchValue({
                totalDue: this.customCurrency.transform(this.balanceDue.toString()),
                cashAmount: this.customCurrency.transform(this.balanceDue.toString())
              })
            }
          } else {
            {
              this.actualTotalDue = this.balanceDue
              this.paymentForm.controls['paymentDetails'].patchValue({
                totalDue: this.customCurrency.transform(this.balanceDue.toString()),
                cashAmount: this.customCurrency.transform(this.balanceDue.toString())
              })
            }
          }
        } else {
          switch (this.paymentData.RentalType) {
            case 1:
              this.thermalPrinting = res.PrintThermalDaily;
              break
            case 2:
              this.thermalPrinting = res.PrintThermalMonthly;
              break
            case 3:
              this.thermalPrinting = res.PrintThermalWeekly;
              break
            case 4:
              this.thermalPrinting = res.PrintThermalCM;
              break;
            case 5:
              this.thermalPrinting = res.PrintThermalCW;
              break;
          }
        }
      }
      if (!this.boothTransfer) {
        this.actualTotalDue = this.paymentData.TotalBalanceDue;
        if (this.displayAmountPaid) {
          if (this.paymentData) {
            this.paymentForm.controls['paymentDetails'].patchValue({
              totalDue: this.customCurrency.transform(this.paymentData.TotalBalanceDue.toString()),
              cashAmount: this.customCurrency.transform(this.paymentData.TotalBalanceDue.toString())
            })
          }
        }
        else {
          this.paymentForm.controls['paymentDetails'].patchValue({
            totalDue: this.customCurrency.transform(this.paymentData.TotalBalanceDue.toString()),
            balanceAmount: this.customCurrency.transform(this.paymentData.TotalBalanceDue.toString())
          })
        }
      }
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  getCustomPrinterSettting() {
    const sub = forkJoin({
      res: this.adminPrinterSettingService.GetUfmprinterSetting(this.marketId),
      customSettings: this.operationRentalService.getCustomSettings(),
    }).subscribe(
      ({ res, customSettings }: any) => {
        if (customSettings) {
          this.displayUFMPrinterSettings = customSettings.PrinterSettings_UFM;
        }
        if (res) {
          switch (this.paymentData.RentalType) {
            case 1:
              this.cashThermalCopies = res.DailyPaymentCashThermalCopies;
              this.cashLaserCopies = res.DailyPaymentCashLaserCopies;
              this.checkThermalCopies = res.DailyPaymentCheckThermalCopies;
              this.checkLaserCopies = res.DailyPaymentCheckLaserCopies;
              this.creditThermalCopies = res.DailyPaymentCcthermalCopies;
              this.creditLaserCopies = res.DailyPaymentCclaserCopies;
              this.directDebitThermalCopies = res.DailyPaymentDirectDebitThermalCopies;
              this.directDebitLaserCopies = res.DailyPaymentDirectDebitLaserCopies;
              break;
            case 2:
              this.cashThermalCopies = res.MonthlyPaymentCashThermalCopies;
              this.cashLaserCopies = res.MonthlyPaymentCashLaserCopies;
              this.checkThermalCopies = res.MonthlyPaymentCheckThermalCopies;
              this.checkLaserCopies = res.MonthlyPaymentCheckLaserCopies;
              this.creditThermalCopies = res.MonthlyPaymentCcthermalCopies;
              this.creditLaserCopies = res.MonthlyPaymentCclaserCopies;
              this.directDebitThermalCopies = res.MonthlyPaymentDirectDebitThermalCopies;
              this.directDebitLaserCopies = res.MonthlyPaymentDirectDebitLaserCopies;
              break;
            case 3:
              this.cashThermalCopies = res.WeeklyPaymentCashThermalCopies;
              this.cashLaserCopies = res.WeeklyPaymentCashLaserCopies;
              this.checkThermalCopies = res.WeeklyPaymentCheckThermalCopies;
              this.checkLaserCopies = res.WeeklyPaymentCheckLaserCopies;
              this.creditThermalCopies = res.WeeklyPaymentCcthermalCopies;
              this.creditLaserCopies = res.WeeklyPaymentCclaserCopies;
              this.directDebitThermalCopies = 0;
              this.directDebitLaserCopies = res.WeeklyPaymentDirectDebitLaserCopies;
              break;
            case 4:
              this.cashThermalCopies = res.CmpaymentCashThermalCopies;
              this.cashLaserCopies = res.CmpaymentCashLaserCopies;
              this.checkThermalCopies = res.CmpaymentCheckThermalCopies;
              this.checkLaserCopies = res.CmpaymentCheckLaserCopies;
              this.creditThermalCopies = res.CmpaymentCcthermalCopies;
              this.creditLaserCopies = res.CmpaymentCclaserCopies;
              this.directDebitThermalCopies = res.CmpaymentDirectDebitThermalCopies;
              this.directDebitLaserCopies = res.CmpaymentDirectDebitLaserCopies;
              break;
            case 5:
              this.cashThermalCopies = res.CwpaymentCashThermalCopies;
              this.cashLaserCopies = res.CwpaymentCashLaserCopies;
              this.checkThermalCopies = res.CwpaymentCheckThermalCopies;
              this.checkLaserCopies = res.CwpaymentCheckLaserCopies;
              this.creditThermalCopies = res.CwpaymentCcthermalCopies;
              this.creditLaserCopies = res.CwpaymentCclaserCopies;
              this.directDebitThermalCopies = res.CwpaymentDirectDebitThermalCopies;
              this.directDebitLaserCopies = res.CwpaymentDirectDebitLaserCopies;
              break;
          }
        }
      });
  }

  getPrinterAdditionalSettings() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.adminPrinterSettingService.getPrinterAdditionalSettings(this.marketId).subscribe((res: any) => {
      if (res) {
        this.paymentForm.controls['paymentDetails'].get('displayReceipt').setValue(res.ReceiptDefaultChecked);
        this.paymentForm.controls['paymentDetails'].get('displayContract').setValue(res.ContractDefaultChecked);
        this.paymentForm.controls['paymentDetails'].get('emailReceipt').setValue(res.EMailReceipt);
        this.paymentForm.controls['paymentDetails'].get('emailContract').setValue(res.EmailContract);
        switch (this.paymentData.RentalType) {
          case 1:
            this.paymentForm.controls['paymentDetails'].get('printReceipt').setValue(res.PrintDailyReceipt);
            this.paymentForm.controls['paymentDetails'].get('printContract').setValue(res.PrintDailyContract);
            break
          case 2:
            this.paymentForm.controls['paymentDetails'].get('printReceipt').setValue(res.PrintMonthlyReceipt);
            this.paymentForm.controls['paymentDetails'].get('printContract').setValue(res.PrintMonthlyContract);
            break
          case 3:
            this.paymentForm.controls['paymentDetails'].get('printReceipt').setValue(res.PrintWeeklyReceipt);
            this.paymentForm.controls['paymentDetails'].get('printContract').setValue(res.PrintWeeklyContract);
            break
          case 4:
            this.paymentForm.controls['paymentDetails'].get('printReceipt').setValue(res.PrintCMReceipt);
            this.paymentForm.controls['paymentDetails'].get('printContract').setValue(res.PrintCMContract);
            break;
          case 5:
            this.paymentForm.controls['paymentDetails'].get('printReceipt').setValue(res.PrintCWReceipt);
            this.paymentForm.controls['paymentDetails'].get('printContract').setValue(res.PrintCWContract);
            break;
        }

        if (this.paymentData.ReceiptSettings) {
          var settings = this.paymentData.ReceiptSettings;
          this.paymentForm.controls['paymentDetails'].get('displayReceipt').setValue(settings.displayReceipt);
          this.paymentForm.controls['paymentDetails'].get('printReceipt').setValue(settings.printReceipt);
          this.paymentForm.controls['paymentDetails'].get('emailReceipt').setValue(settings.emailReceipt);
          // if (!this.paymentOnly) {
            this.paymentForm.controls['paymentDetails'].get('displayContract').setValue(settings.displayContract);
            this.paymentForm.controls['paymentDetails'].get('printContract').setValue(settings.printContract);
            this.paymentForm.controls['paymentDetails'].get('emailContract').setValue(settings.emailContract);
          // }
          // // else{
          //   this.paymentForm.controls['paymentDetails'].get('displayContract').setValue(false);
          //   this.paymentForm.controls['paymentDetails'].get('printContract').setValue(false);
          //   this.paymentForm.controls['paymentDetails'].get('emailContract').setValue(false);
        //  }
        }
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  numberOnly(event): boolean {
    const inputValue = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    const currentCursorPosition = event.target.selectionStart;
    const currentSelectionLength = event.target.selectionEnd - event.target.selectionStart;
    // Check if the input value is "$0.00"
    if (charCode == 45) {
      return false;
    }
    if (currentSelectionLength === inputValue.length) {
      return true;
    }
    if (inputValue === '$0.00') {
      // Set the cursor position after the "$" sign
      event.target.setSelectionRange(1, 1);
      return true; // Allow further input
    }
    // Check if the "$" sign is present in the current value
    const hasDollarSign = inputValue.includes('$');
    // Check if the cursor position is after the "$" sign
    const hasDollarSignBeforeCaret = inputValue.slice(0, currentCursorPosition).includes('$');
    // If the cursor is not after the "$" sign and "$" sign is present, prevent input
    if (!hasDollarSignBeforeCaret && hasDollarSign && charCode !== 46 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    // If the entered character is a dot and there's already a dot in the input, prevent input
    if (charCode == 46 && inputValue.includes('.') && inputValue.indexOf('.') !== currentCursorPosition) {
      return false;
    }
    if (charCode > 31 && (charCode < 46 || charCode > 57 || charCode === 47)) {
      return false;
    }
    return true; // Allow input
  }

  numberOnlyForCash(event: any) {
    // Get the current value of the input field
    const currentValue: string = event.target.value;
    const currentCursorPosition = event.target.selectionStart;
    const currentSelectionLength = event.target.selectionEnd - event.target.selectionStart;
    if (currentSelectionLength === currentValue.length) {
      return true;
    }
    // Check if the input value is "0" and if it contains a "$" sign
    if (currentValue === '0' && !currentValue.includes('$')) {
      // Set the new value with "$" sign
      event.target.value = '$0';
      // Set the cursor position after the "$" sign
      event.target.setSelectionRange(1, 1);
      return;
    }
    // Check if the "$" sign is present in the current value
    const hasDollarSign = currentValue.includes('$');
    // Allow input if the "$" sign is not present
    if (!hasDollarSign) {
      return;
    }
    // Get the cursor position
    const caretPosition = event.target.selectionStart;
    // Check if the cursor position is after the "$" sign
    const hasDollarSignBeforeCaret = currentValue.slice(0, caretPosition).includes('$');
    // If the cursor is not after the "$" sign, prevent input
    if (!hasDollarSignBeforeCaret) {
      event.preventDefault();
      return;
    }

    // Allow digits, a dot, and an optional '-' at the beginning
    const pattern = /^-?\d*\.?\d*$/;
    // Get the character entered by the user
    const inputChar = String.fromCharCode(event.charCode);
    // Check if the input matches the pattern
    if (!pattern.test(inputChar) || // If the entered character is not allowed
      (inputChar === '.' && currentValue.includes('.')) || // Or if the dot is already present
      (inputChar === '-' && currentValue.indexOf('-') !== -1)) { // Or if '-' is entered more than once
      event.preventDefault(); // Prevent the character from being entered
    }
  }

  handledblClick(event: any) {
    clearTimeout(this.clickTimeout);

    const inputElement = event.target as HTMLInputElement;
    inputElement.select(); // Select the entire text on double-click
  }

  handleClick(event: any) {
    // Get the current value of the input field
    let currentValue: string = event.target.value;
    const currentCursorPosition = event.target.selectionStart;
    const currentSelectionLength = event.target.selectionEnd - event.target.selectionStart;
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    clearTimeout(this.clickTimeout);

    this.clickTimeout = setTimeout(() => {
      if (inputValue === '$0.00') {
        const selectionLength = inputElement.selectionEnd - inputElement.selectionStart;

        // If the entire text is not selected, set the cursor position after the "$" sign
        if (selectionLength !== inputValue.length) {
          inputElement.setSelectionRange(1, 1);
        }
      }
    }, 200); // Delay to check for double click
  
    if (currentSelectionLength === currentValue.length) {
      return true;
    }
    // Check if the input value is "$0.00"
    if (currentValue === '$0.00') {
      // Set the cursor position after the "$" sign
      event.target.setSelectionRange(1, 1);
      return;
    }

    // Check if the "$" sign is present in the current value
    const hasDollarSign = currentValue.includes('$');
    // Get the cursor position
    const caretPosition = event.target.selectionStart;
    // Check if the cursor position is after the "$" sign
    const hasDollarSignBeforeCaret = currentValue.slice(0, caretPosition).includes('$');
    // If the cursor is not after the "$" sign, adjust the cursor position
    if (!hasDollarSignBeforeCaret && hasDollarSign) {
      // Find the index of "$" sign
      const dollarSignIndex = currentValue.indexOf('$');
      // Set the cursor position after the "$" sign
      event.target.setSelectionRange(dollarSignIndex + 1, dollarSignIndex + 1);
    }
  }

  saveRentalWithPayment() {    
    const checkAmount = this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, ''));
    var creditCardAmount = this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const cashAmount = this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const directDebitAmount = this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, ''));
    var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
    const totalAmountPaid = checkAmount + creditCardAmount + cashAmount + directDebitAmount + convenienceFee;
    if (totalAmountPaid != 0) {
      if (checkAmount > 0 && this.paymentForm.controls['paymentDetails'].value['checkNo'] == '') {
        Swal.fire({
          title: $localize`Warning`,
          text: $localize`Please enter the check#.`,
          icon: "warning",
          confirmButtonText: $localize`Ok`,
        }).then((result) => {
          if (result.value) {
            if (this.checkNo)
            this.checkNo.nativeElement.focus();
          }
        });      
      }
      else if (creditCardAmount > 0 && !this.paymentForm.controls['paymentDetails'].value['creditCardTypes']) {
        Swal.fire({
          title: $localize`Warning`,
          text: $localize`Please select the card type.`,
          icon: "warning",
          confirmButtonText: $localize`Ok`,
        });
      }
      else {
        Swal.fire({
          title: $localize`Confirm`,
          text: $localize`Are you sure you want to proceed with the payment?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: $localize`Yes`,
          cancelButtonText: $localize`No`,
        }).then((result) => {
          if (result.value) {
            this.disablePayBtnAfterSubmit = true;
            if (creditCardAmount > 0) {
              if (this.paymentGatewayCredentials && this.processCreditCardOnline)
                this.processPayment = true;
              else {
                var obj = {
                  PaymentSuccess: true,
                  OrderId: "",
                  VerifoneId: 0
                }
                this.PaymentResponse(obj)
              }
            }
            else {
              var obj = {
                PaymentSuccess: true,
                OrderId: "",
                VerifoneId: 0
              }
              this.PaymentResponse(obj)
            }
          }
        });
      }
    }
    else {
      Swal.fire({
        title: $localize`Warning`,
        text: $localize`Please enter the cash/card or check amount!`,
        icon: "warning",
        confirmButtonText: $localize`Ok`,
      });
    }
  }

  getThermalNoOfCopies() {
    var thermalNoOfCopies = 0;
    const cashAmount = this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const checkAmount = this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const cardAmount = this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const directDebitAmount = this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, ''));
    thermalNoOfCopies = cardAmount > 0 && this.creditThermalCopies? this.creditThermalCopies : 
                        cashAmount != 0 && this.cashThermalCopies ? this.cashThermalCopies :
                        checkAmount > 0 && this.checkThermalCopies ? this.checkThermalCopies:
                        directDebitAmount > 0 && this.directDebitThermalCopies ? this.directDebitThermalCopies : 0;                       
    return thermalNoOfCopies;
  }

  getLaserNoOfCopies() {
    var LaserNoOfCopies = 0;
    const cashAmount = this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const checkAmount = this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const cardAmount = this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, ''));
    const directDebitAmount = this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, ''));
    LaserNoOfCopies = cardAmount > 0 && this.creditLaserCopies ? this.creditLaserCopies : 
                        cashAmount != 0 && this.cashLaserCopies? this.cashLaserCopies :
                        checkAmount > 0 && this.checkLaserCopies? this.checkLaserCopies:
                        directDebitAmount > 0 && this.directDebitLaserCopies? this.directDebitLaserCopies : 0;                        
    return LaserNoOfCopies;
  }

  PaymentResponse(response) {
    this.loaderIcon = Promise.resolve(true);
    if (response.PaymentSuccess) {
      this.verifoneId = response.VerifoneId;
      if (this.boothTransfer) {
        const obj2 = {
          VendorId: this.vendorId,
          PaymentOrderId: response.OrderId,
          TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')),
          CashAmount: this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')),
          CheckAmount: this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')),
          CheckNo: this.paymentForm.controls['paymentDetails'].get('checkNo').value,
          CardAmount: this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')),
          DirectDebitAmount:  this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')),
          CardType: this.paymentForm.controls['paymentDetails'].get('creditCardTypes').value,
          PaymentDone: true,
          VerifoneId: response.VerifoneId,
          DiscountAmount:  0
        };
        this.loaderIcon = Promise.resolve(false);
        this.paymentResponse.emit(obj2);
        this.closePaymentComponent.emit(); 
      }
      if (this.IsQDR) {
        var obj2 = {
          VendorId: this.vendorId,
          PaymentOrderId: response.OrderId,
          VendorName: this.sharedDataService.paymentSharedData.VendorName,
          TransactionDate: this.sharedDataService.paymentSharedData.TransactionDate,
          Address: this.sharedDataService.paymentSharedData.Address,
          DLno: this.sharedDataService.paymentSharedData.DLno,
          DLExpDate: this.sharedDataService.paymentSharedData.DLExpDate,

          RentalCharge: this.paymentData.RentalCharge,
          SalesTax: this.paymentData.SalesTax,
          SecLevelTax: this.paymentData.SecLevelTax,
          OtherCharge: this.paymentData.OtherCharge,
          TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')),
          RentalNotes: this.paymentData.RentalNotes,
          CashAmount: this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')),
          CheckAmount: this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')),
          CheckNo: this.paymentForm.controls['paymentDetails'].get('checkNo').value,
          CardAmount: this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')),
          DirectDebitAmount:  this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')),
          CardType: this.paymentForm.controls['paymentDetails'].get('creditCardTypes').value,
          PaymentDone: true,
          VerifoneId: response.VerifoneId,
          DailyRentalBooth: [],
          DailyRentalOtherCharges: [],
          AppliedDiscountModel : [],
          DiscountAmount:  this.paymentData.DiscountAmount,
        }

        if (this.paymentData.DailyRentalBooth.length > 0)
          obj2.DailyRentalBooth = this.paymentData.DailyRentalBooth;

        if (this.paymentData.DailyRentalOtherCharges.length > 0)
          obj2.DailyRentalOtherCharges = this.paymentData.DailyRentalOtherCharges;
       
        if (this.paymentData.AppliedDiscountModel.length > 0)
          obj2.AppliedDiscountModel = this.paymentData.AppliedDiscountModel;

        const sub = this.operationRentalService.SaveQuickDailyRental(obj2).subscribe((res: any) => {
          if (res.Success) {
            this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
          }
          else {
            this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
            this.loaderIcon = Promise.resolve(false);
            if (this.verifoneId) {
              this.voidPayment = true;
            }
            const url = 'ops/qdr';
            this.router.navigate([url]);
            return;
          }
          
          this.sharedDataService.paymentSharedData = {};
          
          if ( this.paymentForm.controls['paymentDetails'].get('displayReceipt').value ) {
          
            var obj = {
              VendorId: parseInt(this.vendorId),
              AccountReceiptId: res.Id,
              DisplayBillNum : this.paymentForm.controls['paymentDetails'].value['PrintBillNum']
            }
            
            this.subscriptions.push(this.operationRentalService.DisplayQDRPaymentReceipt(obj).subscribe((res1: any) => {
              this.loaderIcon = Promise.resolve(true);
              if (res1.Success) {              
                const iframe = this.receiptIframe.nativeElement
                iframe.src = res1.Message;
                iframe.style.height = '700px';
                iframe.style.width = '100%';
              
                this.printReceipt.show();
                this.loaderIcon = Promise.resolve(false);
              }else{
                this.loaderIcon = Promise.resolve(false);
              }
            }));
            
            return;
          }

          if ( this.paymentForm.controls['paymentDetails'].get('printReceipt').value ) {
            
            var obj = {
              VendorId: parseInt(this.vendorId),
              AccountReceiptId: res.Id,
              DisplayBillNum : this.paymentForm.controls['paymentDetails'].value['PrintBillNum']
            }
            this.subscriptions.push(this.operationRentalService.PrintQDRPaymentReceipt(obj).subscribe((res1: any) => {
              if (res1.Success) {   
                     
                setTimeout(() => {
                  printJS({printable: res1.Message.toString().split(',')[1], type:'pdf',base64:true, onPrintDialogClose: () => {
                    this.closeReceiptDisplay();
                  }});
                }, 1000);

                //this.print(res1.Message.toString().split(',')[1]);
              
               

              //nodep.print(res1.Message.toString().split(',')[1], { silent: true });


              }
            }));
            this.loaderIcon = Promise.resolve(false);
            return
          }

            const url = 'ops/qdr';
            this.router.navigate([url]);

          

          this.loaderIcon = Promise.resolve(false);
        }, (err) => {
          this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
          const url = 'ops/qdr';
          this.loaderIcon = Promise.resolve(false);
        });
        this.subscriptions.push(sub);

      }
      else if(this.paymentData.RentalMethod == "deposit")
      {
        var objDeposit = {
          VendorId: this.vendorId,
          PaymentOrderId: response.OrderId,
          PriorBalance: this.paymentData.PriorBalance,
          DepositAmount: this.paymentData.DepositAmount,
          TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')),
          RentalNotes: this.paymentData.RentalNotes,
          CashAmount: this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')),
          CheckAmount: this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')),
          CheckNo: this.paymentForm.controls['paymentDetails'].get('checkNo').value,
          ChangeAmount: this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')),
          CardAmount: this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')),
          DirectDebitAmount:  this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')),
          CardType: this.paymentForm.controls['paymentDetails'].get('creditCardTypes').value,
          PaymentDone: true,
          VerifoneId: response.VerifoneId,
          RentalType:this.paymentData.RentalType
        }

        const sub = this.operationRentalService.SaveDeposit(objDeposit).subscribe((res: any) => {          
          if (res.Success) {
            const settings = this.paymentForm.controls['paymentDetails'].value;
            this.sharedDataService.paymentSharedData.DepositAmount = 0
            this.sharedDataService.paymentSharedData.PriorBalance = 0
            this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
            if (res.Id !== 0) {
              if (settings.displayReceipt || settings.printReceipt || settings.displayContract || settings.printContract
                || settings.emailReceipt || settings.emailContract) {
                this.generatePaymentReceiptAndContract(res.Id);
               
              } else {
                const url = 'ops/vendors/details/' + this.vendorId + '/3';
                this.router.navigate([url]);
              }
            }
            else {
              const url = 'ops/vendors/details/' + this.vendorId + '/3';
              this.router.navigate([url]);
            }
          }
          else {
            this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig);
            if (this.verifoneId) {
              this.voidPayment = true;
            }
          }
          this.loaderIcon = Promise.resolve(false);
        }, (err) => {
          this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
          const url = 'ops/vendors/details/' + this.vendorId + '/3';
          this.loaderIcon = Promise.resolve(false);
        });
        this.subscriptions.push(sub);
      }
      else {      
          
        switch (this.paymentData.RentalType) {
          case 1:
            var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
            if (convenienceFee > 0 && this.paymentOnly) {
              this.paymentOnly = false;
            }
            var obj = {
              VendorId: this.vendorId,
              PaymentOrderId: response.OrderId,
              RentalCharge: this.paymentData.RentalCharge,
              SalesTax: this.paymentData.SalesTax,
              SecLevelTax: this.paymentData.SecLevelTax,
              OtherCharge: this.paymentData.OtherCharge,
              PriorBalance: this.paymentData.PriorBalance,
              TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')),
              RentalNotes: this.paymentData.RentalNotes,
              CashAmount: this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckAmount: this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckNo: this.paymentForm.controls['paymentDetails'].get('checkNo').value,
              ChangeAmount: this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CardAmount: this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')),
              DirectDebitAmount:  this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')),          
              CardType: this.paymentForm.controls['paymentDetails'].get('creditCardTypes').value,
              PaymentDone: true,
              PaymentDueDate: this.paymentData.PaymentDueDate,
              VerifoneId: response.VerifoneId,
              DiscountAmount:  this.paymentData.DiscountAmount,
              ConvenienceFee: this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')),
              DailyRentalBooth: [],
              DailyRentalOtherCharges: [],
              AppliedDiscountModel: []
            }

            if (this.paymentData.DailyRentalBooth.length > 0)
              obj.DailyRentalBooth = this.paymentData.DailyRentalBooth;

            if (this.paymentData.DailyRentalOtherCharges.length > 0)
              obj.DailyRentalOtherCharges = this.paymentData.DailyRentalOtherCharges;

            if (this.paymentData.AppliedDiscountModel.length > 0)
              obj.AppliedDiscountModel = this.paymentData.AppliedDiscountModel;

              const sub = this.operationRentalService.SaveDailyRental(obj).subscribe(async (res: any) => {
                if (res.Success) {
                  this.sharedDataService.discountSelected = [];
                  this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
                  const settings = this.paymentForm.controls['paymentDetails'].value;
                  if (res.Id !== 0) {
                     if (settings.displayReceipt || settings.printReceipt || settings.displayContract || settings.printContract
                      || settings.emailReceipt || settings.emailContract) {
                      var printReceipt;
                      const handleReceipt = () => {
                        if (settings.printReceipt) {
                          if (this.displayUFMPrinterSettings) {
                            var thermalNoOfCopies = this.getThermalNoOfCopies()
                            if (thermalNoOfCopies && thermalNoOfCopies >= 0) {
                              const customProtocolUrl = 'silentprinter://?methodname=RentalPaymentReceipt&param1=' + res.Id + '&param2=1&param3=' + parseInt(this.vendorId) + '&param4=False&param5=' + thermalNoOfCopies;
                              window.location.href = customProtocolUrl;
                            }
                          }
                          else if (this.thermalPrinting) {
                            this.operationRentalService.GetRentalPrintReceiptNoOfCopies(1).subscribe((response: string) => {
                              const customProtocolUrl = 'silentprinter://?methodname=RentalPaymentReceipt&param1=' + res.Id + '&param2=1&param3=' + parseInt(this.vendorId) + '&param4=' + this.paymentOnly + '&param5=' + response;
                              window.location.href = customProtocolUrl;
                            });
                          }
                        }

                        if (settings.displayReceipt || (settings.printReceipt && !this.thermalPrinting) || settings.emailReceipt) {
                          const params = {
                            VendorId: parseInt(this.vendorId),
                            AccountReceiptId: res.Id,
                            PaymentOnly: this.paymentOnly,
                           
                          };

                          this.subscriptions.push(
                            this.operationRentalService.getDailyPaymentReceipt(params).subscribe((res1: any) => {
                              if (res1.Success) {
                                printReceipt = res1.Message;
                                const message = res1.Message;
                                const iframe = this.receiptIframe.nativeElement;
                                iframe.src = message;
                                iframe.style.height = '700px';
                                iframe.style.width = '100%';

                                if (settings.displayReceipt) {
                                  this.printReceipt.show();
                                  this.previewReceiptPopupOpen = true;
                                }

                                var emailData;
                                if (settings.emailReceipt) {
                                  this.emailReceipt = true;
                                  emailData = [{
                                    AccountReceiptID: res.Id,
                                    VendorID: parseInt(this.vendorId),
                                    BaseTemplateID: this.paymentOnly ? 7 : 1,
                                    AttachmentFile: res1.Message,
                                    AttachmentFileName: this.paymentOnly ? "Payment Receipt" : "Rental Receipt",
                                    FileType: "application/pdf",
                                    Subject: this.paymentOnly ? "Payment Receipt" : "Daily Rental Receipt"
                                  }];

                                  this.operationRentalService.ComposeEmails(emailData).subscribe((emailResponse: any) => {
                                    this.emailReceipt = false;
                                    this.routeBackToRentals();
                                    console.log("Payment receipt email delivered");
                                  });
                                }

                                if (settings.printReceipt && !settings.printContract) {
                                  let allBase64Strings: string[] = [];
                                  if (this.displayUFMPrinterSettings) {
                                    const laserNoOfCopies = this.getLaserNoOfCopies();
                                    if (laserNoOfCopies) {
                                      for (var i = 0; i < laserNoOfCopies; i++) {
                                        allBase64Strings.push(res1.Message.replace(/^data:.*;base64,/, ''));
                                      }

                                      this.subscriptions.push(this.operationRentalService.combinePdfs(allBase64Strings).subscribe(response => {
                                        if (response.Success) {
                                          printJS({
                                            printable: response.CombinedPdf.replace("data:application/pdf;base64,", ""),
                                            type: 'pdf',
                                            base64: true,
                                            onPrintDialogClose: () => {
                                              this.printReceiptPopupOpen = false;
                                              this.routeBackToRentals();
                                            },
                                          });
                                        }
                                      }));
                                    }
                                  }
                                  else if (!this.thermalPrinting) {
                                    this.printReceiptPopupOpen = true;
                                    printJS({
                                      printable: message.toString().split(',')[1],
                                      type: 'pdf',
                                      base64: true,
                                      onPrintDialogClose: () => {
                                        this.printReceiptPopupOpen = false;
                                        this.routeBackToRentals();
                                      },
                                    });
                                  }
                                }
                              }
                            })
                          );
                        }
                      };

                      const handleContract = () => {
                        if (settings.displayContract || settings.printContract || settings.emailContract) {
                          const params = {
                            VendorId: parseInt(this.vendorId),
                            AccountReceiptId: res.Id,
                            RentalType: 1,
                            PaymentOnly: this.paymentOnly
                          };

                          this.subscriptions.push(
                            this.operationRentalService.generateRentalContract(params).subscribe((res2: any) => {
                              if (res2.Success) {
                                const message = res2.Message;
                                const iframe = this.contractIframe.nativeElement;
                                iframe.src = message;
                                iframe.style.height = '700px';
                                iframe.style.width = '100%';

                                if (settings.displayContract) {
                                  this.previewContractPopupOpen = true;
                                  setTimeout(() => this.printContractPopup.show(), 1000);
                                }

                                var contract = {
                                  AccountReceiptId: res.Id,
                                  contractData: res2.Message
                                }
                                this.operationRentalService.UploadRentalContractToBlob(contract).subscribe(() => {
                                });

                                var emailContractData;
                                if (settings.emailContract) {
                                  this.emailContract = true;
                                  emailContractData = [{
                                    AccountReceiptID: res.Id,
                                    VendorID: parseInt(this.vendorId),
                                    BaseTemplateID: 8,
                                    AttachmentFile: res2.Message,
                                    AttachmentFileName: "Rental Contract",
                                    FileType: "application/pdf",
                                    Subject: "Daily Rental Contract"
                                  }];
            
                                  this.operationRentalService.ComposeEmails(emailContractData).subscribe((emailResponse: any) => {
                                    this.emailContract = false;
                                    this.routeBackToRentals();
                                    console.log("Payment receipt email delivered");
                                  });
                                }

                                if (settings.printContract && !settings.printReceipt) {
                                  setTimeout(() => {
                                    this.printContractPopupOpen = true;
                                    printJS({
                                      printable: message.toString().split(',')[1],
                                      type: 'pdf',
                                      base64: true,
                                      onPrintDialogClose: () => {
                                        this.printContractPopupOpen = false;
                                        this.routeBackToRentals();
                                       },
                                    });
                                  }, 500);
                                }
                                else if (settings.printContract && settings.printReceipt) {
                                  if (!this.thermalPrinting) {
                                    this.printContractPopupOpen = true;
                                    this.printReceiptPopupOpen = true;
                                    printJS({
                                      printable: printReceipt.toString().split(',')[1],
                                      type: 'pdf',
                                      base64: true,
                                      onPrintDialogClose: () => {
                                        this.printReceiptPopupOpen = false;
                                        // First print completed, now initiate the second print
                                        this.printSecondPDF(res2);
                                      }
                                    });
                                  }
                                  else{
                                    this.printContractPopupOpen = true;
                                    this.printSecondPDF(res2);
                                  }
                                }
                              }
                            })
                          );
                        }
                      };

                      handleReceipt();
                      handleContract();
                    } else {
                      if (this.IsQDR) {
                        this.router.navigate(['ops/qdr']);
                      } else {
                        const url = 'ops/vendors/details/' + this.vendorId + '/3';
                        this.router.navigate([url]);
                      }
                    }
                  }
                  else {                    
                      if (this.IsQDR) {
                        this.router.navigate(['ops/qdr']);
                      } else {
                        const url = 'ops/vendors/details/' + this.vendorId + '/3';
                        this.router.navigate([url]);
                      }
                  }

                  if(this.paymentData.TempRecordNo != 0){
                    const temp = {
                      TempRecordNo: this.paymentData.TempRecordNo.toString(),
                      BoothNumber: ""
                    }
                    this.subscriptions.push(
                      this.operationRentalService.deleteFromTemp(temp).subscribe((res: any) => {
                      })
                    );
                  }

                  this.sharedDataService.paymentSharedData = '';
                } else {
                  this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
                 if (this.verifoneId) {
                    this.voidPayment = true;
                  }
                  else {
                    if (this.IsQDR) {
                      const url = 'ops/qdr';
                      this.router.navigate([url]);
                    } else {
                      const url = 'ops/vendors/details/' + this.vendorId + '/3';
                      this.router.navigate([url]);
                    }
                  }
                  if(this.paymentData.TempRecordNo != 0){
                    const temp = {
                      TempRecordNo: this.paymentData.TempRecordNo.toString(),
                      BoothNumber: ""
                    }
                    this.subscriptions.push(
                      this.operationRentalService.deleteFromTemp(temp).subscribe((res: any) => {
                      })
                    );
                  }
                }              
                this.loaderIcon = Promise.resolve(false);
              }, (err) => {
                this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
              
                if (this.IsQDR) {
                  const url = 'ops/qdr';
                  this.router.navigate([url]);
                } else {
                  const url = 'ops/vendors/details/' + this.vendorId + '/3';
                  this.router.navigate([url]);
                }
              
                this.loaderIcon = Promise.resolve(false);
              });
              
            this.subscriptions.push(sub);
            break;

          case 2:
            var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
            if (convenienceFee > 0 && this.paymentOnly) {
              this.paymentOnly = false;
            }
            var objMonthly = {
              VendorId: this.vendorId,
              PaymentOrderId: response.OrderId,
              RentalCharge: this.paymentData.RentalCharge,
              SalesTax: this.paymentData.SalesTax,
              SecLevelTax: this.paymentData.SecLevelTax,
              OtherCharge: this.paymentData.OtherCharge,
              PriorBalance: this.paymentData.PriorBalance,
              TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')),
              RentalNotes: this.paymentData.RentalNotes,
              CashAmount: this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckAmount: this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckNo: this.paymentForm.controls['paymentDetails'].get('checkNo').value,
              ChangeAmount: this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CardAmount: this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')),
              DirectDebitAmount:  this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CardType: this.paymentForm.controls['paymentDetails'].get('creditCardTypes').value,
              PaymentDone: true,
              ConvenienceFee: this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')),
              DiscountAmount:  this.paymentData.DiscountAmount,
              PaymentDueDate: this.paymentData.PaymentDueDate,
              VerifoneId: response.VerifoneId,
              MonthlyRentalBooth: [],
              MonthlyOtherRentalCharges: [],
              AppliedDiscountModel: []
            }

            if (this.paymentData.MonthlyRentalBooth.length > 0)
              objMonthly.MonthlyRentalBooth = this.paymentData.MonthlyRentalBooth;


            if (this.paymentData.MonthlyOtherRentalCharges.length > 0)
              objMonthly.MonthlyOtherRentalCharges = this.paymentData.MonthlyOtherRentalCharges;

            if (this.paymentData.AppliedDiscountModel.length > 0)
              objMonthly.AppliedDiscountModel = this.paymentData.AppliedDiscountModel;

            const subMonthly = this.operationRentalService.saveMonthlyRental(objMonthly).subscribe((res: any) => {
              if (res.Success) {
                this.sharedDataService.paymentSharedData = "";
                this.sharedDataService.discountSelected = [];
                this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
                const settings = this.paymentForm.controls['paymentDetails'].value;
                if (res.Id !== 0) {
                  if (settings.displayReceipt || settings.printReceipt || settings.displayContract || settings.printContract
                    || settings.emailReceipt || settings.emailContract) {
                    this.generatePaymentReceiptAndContract(res.Id);
                   
                  } else {
                    const url = 'ops/vendors/details/' + this.vendorId + '/3';
                    this.router.navigate([url]);
                  }
                }
                else {
                  const url = 'ops/vendors/details/' + this.vendorId + '/3';
                  this.router.navigate([url]);
                }
              }
              else {
                if (this.verifoneId) {
                  this.voidPayment = true;
                }
                this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
              }
              this.loaderIcon = Promise.resolve(false);
            }, (err) => {
              this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
              const url = 'ops/vendors/details/' + this.vendorId + '/3';
              this.loaderIcon = Promise.resolve(false);
            });
            this.subscriptions.push(subMonthly);
            break;
          case 4:
            var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
            if (convenienceFee > 0 && this.paymentOnly) {
              this.paymentOnly = false;
            }
            var objCombinedMonthly = {
              VendorId: this.vendorId,
              PaymentOrderId: response.OrderId,
              RentalCharge: this.paymentData.RentalCharge,
              SalesTax: this.paymentData.SalesTax,
              SecLevelTax: this.paymentData.SecLevelTax,
              OtherCharge: this.paymentData.OtherCharge,
              PriorBalance: this.paymentData.PriorBalance,
              TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')),
              RentalNotes: this.paymentData.RentalNotes,
              CashAmount: this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckAmount: this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckNo: this.paymentForm.controls['paymentDetails'].get('checkNo').value,
              ChangeAmount: this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CardAmount: this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')),
              DirectDebitAmount:  this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')),
              ConvenienceFee: this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')),
              CardType: this.paymentForm.controls['paymentDetails'].get('creditCardTypes').value,
              PaymentDone: true,
              PaymentDueDate: this.paymentData.PaymentDueDate,
              VerifoneId: response.VerifoneId,
              DiscountAmount:  this.paymentData.DiscountAmount,
              MonthlyRentalBooth: [],
              MonthlyOtherRentalCharges: [],
              AppliedDiscountModel: []
            }

            if (this.paymentData.MonthlyRentalBooth.length > 0)
              objCombinedMonthly.MonthlyRentalBooth = this.paymentData.MonthlyRentalBooth;


            if (this.paymentData.MonthlyOtherRentalCharges.length > 0)
              objCombinedMonthly.MonthlyOtherRentalCharges = this.paymentData.MonthlyOtherRentalCharges;

            if (this.paymentData.AppliedDiscountModel.length > 0)
              objCombinedMonthly.AppliedDiscountModel = this.paymentData.AppliedDiscountModel;

            const subCM = this.operationRentalService.saveCombinedMonthlyRental(objCombinedMonthly).subscribe((res: any) => {
              if (res.Success) {
                this.sharedDataService.paymentSharedData = "";
                this.sharedDataService.discountSelected = [];
                this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
                const settings = this.paymentForm.controls['paymentDetails'].value;
                if (res.Id !== 0) {
                  if (settings.displayReceipt || settings.printReceipt || settings.displayContract || settings.printContract
                    || settings.emailReceipt || settings.emailContract) {
                    this.generatePaymentReceiptAndContract(res.Id);
                  } else {
                    const url = 'ops/vendors/details/' + this.vendorId + '/3';
                    this.router.navigate([url]);
                  }
                }
                else {
                  const url = 'ops/vendors/details/' + this.vendorId + '/3';
                  this.router.navigate([url]);
                }
              }
              else {
                if (this.verifoneId) {
                  this.voidPayment = true;
                }
                this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
              }
              this.loaderIcon = Promise.resolve(false);
            }, (err) => {
              this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
              const url = 'ops/vendors/details/' + this.vendorId + '/3';
              this.loaderIcon = Promise.resolve(false);
            });
            this.subscriptions.push(subCM);
            break;

          case 5:
            var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
            if (convenienceFee > 0 && this.paymentOnly) {
              this.paymentOnly = false;
            }
            var objCombinedWeekly = {
              VendorId: this.vendorId,
              PaymentOrderId: response.OrderId,
              RentalCharge: this.paymentData.RentalCharge,
              SalesTax: this.paymentData.SalesTax,
              SecLevelTax: this.paymentData.SecLevelTax,
              OtherCharge: this.paymentData.OtherCharge,
              PriorBalance: this.paymentData.PriorBalance,
              TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')),
              RentalNotes: this.paymentData.RentalNotes,
              CashAmount: this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckAmount: this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckNo: this.paymentForm.controls['paymentDetails'].get('checkNo').value,
              ChangeAmount: this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CardAmount: this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')),
              DirectDebitAmount:  this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')),
              ConvenienceFee: this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')),
              CardType: this.paymentForm.controls['paymentDetails'].get('creditCardTypes').value,
              PaymentDone: true,
              DiscountAmount:  this.paymentData.DiscountAmount,
              PaymentDueDate: this.paymentData.PaymentDueDate,
              VerifoneId: response.VerifoneId,
              CombinedWeeklyRentalBooth: [],
              WeeklyRentalOtherCharges: [],
              AppliedDiscountModel: []
            }

            if (this.paymentData.CombinedWeeklyRentalBooth.length > 0)
              objCombinedWeekly.CombinedWeeklyRentalBooth = this.paymentData.CombinedWeeklyRentalBooth;


            if (this.paymentData.WeeklyRentalOtherCharges.length > 0)
              objCombinedWeekly.WeeklyRentalOtherCharges = this.paymentData.WeeklyRentalOtherCharges;

            if (this.paymentData.AppliedDiscountModel.length > 0)
              objCombinedWeekly.AppliedDiscountModel = this.paymentData.AppliedDiscountModel;

            const subCW = this.operationRentalService.saveCombinedWeeklyRentalTransactions(objCombinedWeekly).subscribe((res: any) => {
              if (res.Success) {
                   this.sharedDataService.paymentSharedData = "";
                   this.sharedDataService.discountSelected = [];
                this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
                const settings = this.paymentForm.controls['paymentDetails'].value;
                if (res.Id !== 0) {
                  if (settings.displayReceipt || settings.printReceipt || settings.displayContract || settings.printContract
                    || settings.emailReceipt || settings.emailContract) {
                    this.generatePaymentReceiptAndContract(res.Id);
                  } else {
                    const url = 'ops/vendors/details/' + this.vendorId + '/3';
                    this.router.navigate([url]);
                  }
                }
                else {
                  const url = 'ops/vendors/details/' + this.vendorId + '/3';
                  this.router.navigate([url]);
                }
              }
              else {
                if (this.verifoneId) {
                  this.voidPayment = true;
                }
                this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
              }
              this.loaderIcon = Promise.resolve(false);
            }, (err) => {
              this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
              const url = 'ops/vendors/details/' + this.vendorId + '/3';
              this.loaderIcon = Promise.resolve(false);
            });
            this.subscriptions.push(subCW);
            break;
          case 3:
            var convenienceFee = this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
            if (convenienceFee > 0 && this.paymentOnly) {
              this.paymentOnly = false;
            }
            var objWeekly = {
              VendorId: this.vendorId,
              PaymentOrderId: response.OrderId,
              RentalCharge: this.paymentData.RentalCharge,
              SalesTax: this.paymentData.SalesTax,
              SecLevelTax: this.paymentData.SecLevelTax,
              OtherCharge: this.paymentData.OtherCharge,
              PriorBalance: this.paymentData.PriorBalance,
              TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')),
              RentalNotes: this.paymentData.RentalNotes,
              CashAmount: this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckAmount: this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckNo: this.paymentForm.controls['paymentDetails'].get('checkNo').value,
              ChangeAmount: this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CardAmount: this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')),
              DirectDebitAmount:  this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')),
              ConvenienceFee: this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')),
              CardType: this.paymentForm.controls['paymentDetails'].get('creditCardTypes').value,
              PaymentDone: true,
              DiscountAmount:  this.paymentData.DiscountAmount,
              PaymentDueDate: this.paymentData.PaymentDueDate,
              VerifoneId: response.VerifoneId,
              WeeklyRentalBooth: [],
              WeeklyRentalOtherCharges: [],
              AppliedDiscountModel :[]
            }

            if (this.paymentData.WeeklyRentalBooth.length > 0)
              objWeekly.WeeklyRentalBooth = this.paymentData.WeeklyRentalBooth;


            if (this.paymentData.WeeklyRentalOtherCharges.length > 0)
              objWeekly.WeeklyRentalOtherCharges = this.paymentData.WeeklyRentalOtherCharges;

            if (this.paymentData.AppliedDiscountModel.length > 0)
              objWeekly.AppliedDiscountModel = this.paymentData.AppliedDiscountModel;

            const subW = this.operationRentalService.saveWeeklyRental(objWeekly).subscribe((res: any) => {
              if (res.Success) {
                this.sharedDataService.paymentSharedData = "";
                this.sharedDataService.discountSelected = [];
                this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
                const settings = this.paymentForm.controls['paymentDetails'].value;
                if (res.Id !== 0) {
                  if (settings.displayReceipt || settings.printReceipt || settings.displayContract || settings.printContract
                    || settings.emailReceipt || settings.emailContract) {
                    this.generatePaymentReceiptAndContract(res.Id);
                  } else {
                    const url = 'ops/vendors/details/' + this.vendorId + '/3';
                    this.router.navigate([url]);
                  }
                }
                else {
                  const url = 'ops/vendors/details/' + this.vendorId + '/3';
                  this.router.navigate([url]);
                }
              }
              else {
                this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig);
                if (this.verifoneId) {
                  this.voidPayment = true;
                }
              }
              this.loaderIcon = Promise.resolve(false);
            }, (err) => {
              this.toastr.error($localize`Error occured while saving the rental transaction`, $localize`Error`, this.toasterConfig)
              const url = 'ops/vendors/details/' + this.vendorId + '/3';
              this.loaderIcon = Promise.resolve(false);
            });
            this.subscriptions.push(subW);
            break;
          default:
            
            var objPay = {
              VendorId: this.vendorId,
              PaymentOrderId: response.OrderId,
              RentalCharge: 0,
              SalesTax: 0,
              SecLevelTax: 0,
              OtherCharge: 0,
              PriorBalance: this.paymentData.PriorBalance,
              TotalBalanceDue: this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('balanceAmount').value.toString().replace(/[^0-9.-]/g, '')),
              RentalNotes: '',
              CashAmount: this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('cashAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckAmount: this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('checkAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CheckNo: this.paymentForm.controls['paymentDetails'].get('checkNo').value,
              ChangeAmount: this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('changeAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CardAmount: this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('creditCardAmount').value.toString().replace(/[^0-9.-]/g, '')),
              DirectDebitAmount:  this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes('(') && this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().includes(')') ? -1 * parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.paymentForm.controls['paymentDetails'].get('directDebitAmount').value.toString().replace(/[^0-9.-]/g, '')),
              CardType: this.paymentForm.controls['paymentDetails'].get('creditCardTypes').value,
              PaymentDone: true,
              VerifoneId: response.VerifoneId
            }

            this.loaderIcon = Promise.resolve(true);
            const subPay = this.operationRentalService.SavePaymentRental(objPay).subscribe((res: any) => {
              
              if (res.Success) {
                   this.sharedDataService.paymentSharedData = "";
                   this.toastr.success($localize`Payment done!`, $localize`Success`, this.toasterConfig)
                
                   const settings = this.paymentForm.controls['paymentDetails'].value;
                   if (res.Id !== 0) {
                     if (settings.displayReceipt || settings.printReceipt || settings.displayContract || settings.printContract
                       || settings.emailReceipt || settings.emailContract) {
                       var printReceipt;
                       const handleReceipt = () => {
                         if (settings.displayReceipt || settings.printReceipt || settings.emailReceipt) {
                          const params = {
                            VendorId: parseInt(this.vendorId),
                            AccountReceiptId: res.Id,
                            PaymentOnly: true,
                            RentalType: this.paymentData.RentalType
                          };
                          this.subscriptions.push(
                            this.operationRentalService.GeneratePaymentReceipt(params).subscribe((res1: any) => {
                            
                              if (res1.Success) {
                                  printReceipt = res1.Message;
                                  const message = res1.Message;
                                  const iframe = this.receiptIframe.nativeElement;
                                  iframe.src = message;
                                  iframe.style.height = '700px';
                                  iframe.style.width = '100%';

                                  if (settings.displayReceipt) {
                                    
                                    this.printReceipt.show();
                                    
                                  this.loaderIcon = Promise.resolve(false);
                                    this.previewReceiptPopupOpen = true;
                                  }

                                  var emailData;
                                  if (settings.emailReceipt) {
                                    
                                    this.emailReceipt = true;
                                    emailData = [{
                                      AccountReceiptID: res.Id,
                                      VendorID: parseInt(this.vendorId),
                                      BaseTemplateID:  7 ,
                                      AttachmentFile: res1.Message,
                                      AttachmentFileName: "Payment Receipt",
                                      FileType: "application/pdf",
                                      Subject: "Payment Receipt"
                                    }];

                                    this.operationRentalService.ComposeEmails(emailData).subscribe((emailResponse: any) => {
                                      
                                  this.loaderIcon = Promise.resolve(false);
                                      this.emailReceipt = false;
                                      this.routeBackToRentals();
                                      console.log("Payment receipt email delivered");
                                    });
                                  }

                                  if (settings.printReceipt && !settings.printContract) {
                                    
                                  this.loaderIcon = Promise.resolve(false);
                                    this.printReceiptPopupOpen = true;
                                    printJS({
                                      printable: message.toString().split(',')[1],
                                      type: 'pdf',
                                      base64: true,
                                      onPrintDialogClose: () => {
                                        this.printReceiptPopupOpen = false;
                                        this.routeBackToRentals();
                                      },
                                    });
                                  }
                                }else{
                                  this.loaderIcon = Promise.resolve(false);
                                  this.toastr.warning($localize`Failed to display`, $localize`Success`, this.toasterConfig);
                                  const url = 'ops/vendors/details/' + this.vendorId ;
                                  this.router.navigate([url]);
                                }
                              })
                            );
                         }
                        };
                        const handleContract = () => {
                          if (settings.displayContract || settings.printContract || settings.emailContract) {
                            const params = {
                              VendorId: parseInt(this.vendorId),
                              AccountReceiptId: res.Id,
                              RentalType: this.paymentData.RentalType,
                              PaymentOnly: this.paymentOnly
                            };
                            
                            this.subscriptions.push(
                              
                              this.operationRentalService.generateRentalContract(params).subscribe((res2: any) => {
                                if (res2.Success) {
                                  
                                  const message = res2.Message;
                                  const iframe = this.contractIframe.nativeElement;
                                  iframe.src = message;
                                  iframe.style.height = '700px';
                                  iframe.style.width = '100%';
                    
                                  if (settings.displayContract) {
                                    this.previewContractPopupOpen = true;
                                    setTimeout(() => this.printContractPopup.show(), 1000);
                                  }
                                  
                                  var contract = {
                                    AccountReceiptId: res.Id,
                                    contractData: res2.Message
                                  }
                                  this.operationRentalService.UploadRentalContractToBlob(contract).subscribe(() => {
                                  });
                    
                                  var contractEmailSubject = this.paymentData.RentalType == 2 ? "Monthly Rental Contract" :
                                  this.paymentData.RentalType == 3 ? "Weekly Rental Contract" :
                                    this.paymentData.RentalType == 4 ? "Combined Monthly Rental Contract" :
                                      this.paymentData.RentalType == 4 ? "Combined Weekly Rental Contract" : "Rental Contract";
                    
                                  var emailContractData;
                                  this.loaderIcon = Promise.resolve(false);
                                  if (settings.emailContract) {
                                    this.emailContract = true;
                                    emailContractData = [{
                                      AccountReceiptID: res.Id,
                                      VendorID: parseInt(this.vendorId),
                                      BaseTemplateID: 8,
                                      AttachmentFile: res2.Message,
                                      AttachmentFileName: "Rental Contract",
                                      FileType: "application/pdf",
                                      Subject: contractEmailSubject
                                    }];
                    
                                    this.operationRentalService.ComposeEmails(emailContractData).subscribe((emailResponse: any) => {
                                      this.emailContract = false;
                                      this.routeBackToRentals();
                                      console.log("Payment receipt email delivered");
                                    });
                                    this.loaderIcon = Promise.resolve(false);
                                  }
                    
                                  if (settings.printContract && !settings.printReceipt) {
                                    setTimeout(() => {
                                      this.printContractPopupOpen = true;
                                      printJS({
                                        printable: message.toString().split(',')[1],
                                        type: 'pdf',
                                        base64: true,
                                        onPrintDialogClose: () => {
                                          this.printContractPopupOpen = false;
                                          this.routeBackToRentals();
                                        },
                                      });
                                    }, 500);
                                    this.loaderIcon = Promise.resolve(false);
                                  }
                                  else if (settings.printContract && settings.printReceipt) {
                                    this.printContractPopupOpen = true;
                                    this.printReceiptPopupOpen = true;
                                    printJS({
                                      printable: printReceipt.toString().split(',')[1],
                                      type: 'pdf',
                                      base64: true,
                                      onPrintDialogClose: () => {
                                        this.printReceiptPopupOpen = false;
                                        // First print completed, now initiate the second print
                                        this.printSecondPDF(res2);
                                      }
                                    });
                                    this.loaderIcon = Promise.resolve(false);
                                  }
                                }
                              })
                            );
                          }
                        };
                    
                      handleReceipt();
                      handleContract();
                      }else {
                        
                        this.loaderIcon = Promise.resolve(false);
                const url = 'ops/vendors/details/' + this.vendorId ;
                this.router.navigate([url]);
                      }
                    }

              }
              else {
                this.toastr.error($localize`Error occured while making payment`, $localize`Error`, this.toasterConfig)
                
                this.loaderIcon = Promise.resolve(false);
                if (this.verifoneId) {
                  this.voidPayment = true;
                }
              }
              //this.loaderIcon = Promise.resolve(false);
            });
            this.subscriptions.push(subPay);
            break;
        }
      }
    }
    else{
      this.processPayment = false;
      this.voidPayment = false;
      this.disablePayBtnAfterSubmit = false;
      this.loaderIcon = Promise.resolve(false);
    }
  }

  voidPaymentResponse() {
    this.voidPayment = false;
    if (this.IsQDR) {
      this.router.navigate(['ops/qdr']);
    } else {
      const url = 'ops/vendors/details/' + this.vendorId + '/3';
      this.router.navigate([url]);
    }
  }

  printSecondPDF(pdfData) {
    // Delay for 1000 milliseconds (1 second)
    setTimeout(() => {
      printJS({
        printable: pdfData.Message.toString().split(',')[1],
        type: 'pdf',
        base64: true,
        onPrintDialogClose: () => {
          // Second print completed
          this.printContractPopupOpen = false;
          this.routeBackToRentals();
        }
      });
    }, 100);
  }

  getCCCredentials() {
    this.paymentService.getCCCredentials().subscribe((res) => {
      if (res !== null) {
        this.paymentGatewayCredentials = res.GatewayDetails ? JSON.parse(res.GatewayDetails) : null;
        this.processCreditCardOnline = res.ProcessCreditCardOnline;
      }
    })
  }

  ClearPay() {
    this.paymentForm.controls['paymentDetails'].patchValue({
      cashAmount: this.customCurrency.transform("0.00"),
      checkAmount: this.customCurrency.transform("0.00"),
      creditCardAmount: this.customCurrency.transform("0.00"),
      directDebitAmount: this.customCurrency.transform("0.00"),
      convenienceFee: this.customCurrency.transform("0.00"),
      changeAmount: this.customCurrency.transform("0.00"),
      checkNo: '',
      creditCardTypes:'1',
      totalDue:  this.customCurrency.transform(this.actualTotalDue.toString()),
      balanceAmount:this.customCurrency.transform(this.paymentData.TotalBalanceDue.toString())
    });
    this.paymentForm.controls['paymentDetails'].get('creditCardTypes').disable();
    this.paymentForm.controls['paymentDetails'].get('keyedInTransaction').setValue(false);
    this.checkPaymentType();
  }

  goBack(){
    Swal.fire({
      title: $localize`Confirm`,
      text: $localize`Are you sure you want to go back ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: $localize`Yes`,
      cancelButtonText: $localize`No`,
    }).then((result) => {
      if (result.value) {
        if (this.boothTransfer) {
          this.closePaymentComponent.emit();
        }
        else if(this.IsQDR){          
          this.router.navigate(['ops/qdr']);
        }
        else if(this.IsVendorPayment){
          const url = 'ops/vendors/details/' + this.vendorId ;      
          this.router.navigate([url]);
        }else{
          const url = 'ops/vendors/details/' + this.vendorId + '/3';
          this.router.navigate([url]);
      }
      //this.location.();
  }});
  }
  
  closeReceiptDisplay() {
    

          this.printReceipt.hide();
          if (this.IsQDR) {
            const url = 'ops/qdr';
            this.router.navigate([url]);
          } else {
            const url = 'ops/vendors/details/' + this.vendorId + '/3';
            this.router.navigate([url]);
          }
        
     
  }

  generatePaymentReceiptAndContract(accountReceiptId) {
    const settings = this.paymentForm.controls['paymentDetails'].value;
    var printReceipt;
    const handleReceipt = () => {
      if (settings.printReceipt) {
        if (this.displayUFMPrinterSettings) {
          var thermalNoOfCopies = this.getThermalNoOfCopies()
          if (thermalNoOfCopies && thermalNoOfCopies >= 0) {
            const customProtocolUrl = 'silentprinter://?methodname=RentalPaymentReceipt&param1=' + accountReceiptId + '&param2=1&param3=' + parseInt(this.vendorId) + '&param4=False&param5=' + thermalNoOfCopies;
            window.location.href = customProtocolUrl;
          }
        }
        else if (this.thermalPrinting) {
          this.operationRentalService.GetRentalPrintReceiptNoOfCopies(1).subscribe((response: string) => {
            const customProtocolUrl = 'silentprinter://?methodname=RentalPaymentReceipt&param1=' + accountReceiptId + '&param2=1&param3=' + parseInt(this.vendorId) + '&param4=' + this.paymentOnly + '&param5=' + response;
            window.location.href = customProtocolUrl;
          });
        }
      }

      if (settings.displayReceipt || (settings.printReceipt && !this.thermalPrinting) || settings.emailReceipt) {
        const params = {
          VendorId: parseInt(this.vendorId),
          AccountReceiptId: accountReceiptId,
          PaymentOnly: this.paymentOnly,
          RentalType: this.paymentData.RentalType,
          Deposit: this.paymentData.RentalMethod == "deposit" ? true : false
        };

        this.subscriptions.push(
          this.operationRentalService.GeneratePaymentReceipt(params).subscribe((res1: any) => {
            if (res1.Success) {
              printReceipt = res1.Message;
              const message = res1.Message;
              const iframe = this.receiptIframe.nativeElement;
              iframe.src = message;
              iframe.style.height = '700px';
              iframe.style.width = '100%';

              if (settings.displayReceipt) {
                this.printReceipt.show();
                this.previewReceiptPopupOpen = true;
              }

              var emailData;
              var emailSubject;
              emailSubject = this.paymentData.RentalType == 2 ? "Monthly Rental Receipt" :
                this.paymentData.RentalType == 3 ? "Weekly Rental Receipt" :
                  this.paymentData.RentalType == 4 ? "Combined Monthly Rental Receipt" :
                    this.paymentData.RentalType == 5 ? "Combined Weekly Rental Receipt" :
                    this.paymentData.RentalMethod == "deposit" ? "Deposit Receipt" : "Rental Receipt";             

              if (settings.emailReceipt) {
                this.emailReceipt = true;
                emailData = [{
                  AccountReceiptID: accountReceiptId,
                  VendorID: parseInt(this.vendorId),
                  BaseTemplateID: this.paymentOnly ? 7 : 1,
                  AttachmentFile: res1.Message,
                  AttachmentFileName: this.paymentOnly ? "Payment Receipt" : "Rental Receipt",
                  FileType: "application/pdf",
                  Subject: this.paymentOnly ? "Payment Receipt" : emailSubject
                }];

                this.operationRentalService.ComposeEmails(emailData).subscribe((emailResponse: any) => {
                  this.emailReceipt = false;
                  this.routeBackToRentals();
                  console.log("Payment receipt email delivered");
                });
              }

              if (settings.printReceipt && !settings.printContract) {
                let allBase64Strings: string[] = [];
                if (this.displayUFMPrinterSettings) {
                  const laserNoOfCopies = this.getLaserNoOfCopies();
                  if (laserNoOfCopies) {
                    for (var i = 0; i < laserNoOfCopies; i++) {
                      allBase64Strings.push(res1.Message.replace(/^data:.*;base64,/, ''));
                    }

                    this.subscriptions.push(this.operationRentalService.combinePdfs(allBase64Strings).subscribe(response => {
                      if (response.Success) {
                        printJS({
                          printable: response.CombinedPdf.replace("data:application/pdf;base64,", ""),
                          type: 'pdf',
                          base64: true,
                          onPrintDialogClose: () => {
                            this.printReceiptPopupOpen = false;
                            this.routeBackToRentals();
                          },
                        });
                      }
                    }));
                  }
                }
                else if (!this.thermalPrinting) {
                  this.printReceiptPopupOpen = true;
                  printJS({
                    printable: message.toString().split(',')[1],
                    type: 'pdf',
                    base64: true,
                    onPrintDialogClose: () => {
                      this.printReceiptPopupOpen = false;
                      this.routeBackToRentals();
                    },
                  });
                }
              }
            }
          })
        );
      }
    };

    const handleContract = () => {
      if (settings.displayContract || settings.printContract || settings.emailContract) {
        const params = {
          VendorId: parseInt(this.vendorId),
          AccountReceiptId: accountReceiptId,
          RentalType: this.paymentData.RentalType,
          PaymentOnly: this.paymentOnly,
          Deposit: this.paymentData.RentalMethod == "deposit" ? true : false
        };

        this.subscriptions.push(
          this.operationRentalService.generateRentalContract(params).subscribe((res2: any) => {
            if (res2.Success) {
              const message = res2.Message;
              const iframe = this.contractIframe.nativeElement;
              iframe.src = message;
              iframe.style.height = '700px';
              iframe.style.width = '100%';

              if (settings.displayContract) {
                this.previewContractPopupOpen = true;
                setTimeout(() => this.printContractPopup.show(), 1000);
              }

              var contract = {
                AccountReceiptId: accountReceiptId,
                contractData: res2.Message
              }
              this.operationRentalService.UploadRentalContractToBlob(contract).subscribe(() => {
              });

              var contractEmailSubject = this.paymentData.RentalType == 2 ? "Monthly Rental Contract" :
              this.paymentData.RentalType == 3 ? "Weekly Rental Contract" :
                this.paymentData.RentalType == 4 ? "Combined Monthly Rental Contract" :
                  this.paymentData.RentalType == 4 ? "Combined Weekly Rental Contract" : "Rental Contract";

              var emailContractData;
              if (settings.emailContract) {
                this.emailContract = true;
                emailContractData = [{
                  AccountReceiptID: accountReceiptId,
                  VendorID: parseInt(this.vendorId),
                  BaseTemplateID: 8,
                  AttachmentFile: res2.Message,
                  AttachmentFileName: "Rental Contract",
                  FileType: "application/pdf",
                  Subject: contractEmailSubject
                }];

                this.operationRentalService.ComposeEmails(emailContractData).subscribe((emailResponse: any) => {
                  this.emailContract = false;
                  this.routeBackToRentals();
                  console.log("Payment receipt email delivered");
                });
              }

              if (settings.printContract && !settings.printReceipt) {
                setTimeout(() => {
                  this.printContractPopupOpen = true;
                  printJS({
                    printable: message.toString().split(',')[1],
                    type: 'pdf',
                    base64: true,
                    onPrintDialogClose: () => {
                      this.printContractPopupOpen = false;
                      this.routeBackToRentals();
                    },
                  });
                }, 500);
              }
              else if (settings.printContract && settings.printReceipt) {
                this.printContractPopupOpen = true;
                this.printReceiptPopupOpen = true;
                printJS({
                  printable: printReceipt.toString().split(',')[1],
                  type: 'pdf',
                  base64: true,
                  onPrintDialogClose: () => {
                    this.printReceiptPopupOpen = false;
                    // First print completed, now initiate the second print
                    this.printSecondPDF(res2);
                  }
                });
              }
            }
          })
        );
      }
    };

    handleReceipt();
    handleContract();
  }

  closePrintContractPopup(){
    const settings = this.paymentForm.controls['paymentDetails'].value;     
    this.printContractPopup.hide();
    this.previewContractPopupOpen = false;
    this.routeBackToRentals();   
  }

  routeBackToRentals(){
    if(!this.printReceiptPopupOpen && !this.previewReceiptPopupOpen && !this.previewContractPopupOpen && !this.printContractPopupOpen
      && !this.emailContract && !this.emailContract){
        if(this.IsVendorPayment){
          const url = 'ops/vendors/details/' + this.vendorId ;
      
          this.router.navigate([url]);
        }else{
      const url = 'ops/vendors/details/' + this.vendorId + '/3';
      
      this.router.navigate([url]);
        }
    }
  }

  afterPrintHandler(): void {
    this.closeReceiptDisplay();
  }

  ClosePaymentPage(){
    this.processPayment = false;
    this.voidPayment = false;
    this.disablePayBtnAfterSubmit = false;
  }

  ngOnDestroy(): void {
    //this.sharedDataService.paymentSharedData = {};
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
     //this.renderer.removeAttribute('window', 'afterprint', this.afterPrintHandler.bind(this));
  }

}
