import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ECreditCards } from 'src/app/Shared/enum';

@Injectable({
    providedIn: 'root',
})

export class PaymentService {
    private baseURL: string;
    constructor(private http: HttpClient) {
        this.baseURL = environment.apiBaseUrl;
    }

    public options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    getCCCredentials() {
        return this.http.get<any[]>(
            `${this.baseURL}/General/GetCreditCardSettingForMarket`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    return res.body as any;
                }),
                catchError(this.handleError)
            );
    }

    getRCMService(url, request): Observable<any> {
        //const options = { headers: new HttpHeaders({ 'Content-Type': 'application/xml' ,  responseType: 'text/xml'}) };
        return this.http.get<any>(
            `${url}${request}`)
            .pipe(
                map(res => {
                    const responseRCM = res as any;
                    return responseRCM;
                }),
                catchError(this.handleError)
            );
    }

    GetCNPCreditVoid(credential, transId, amount,paymentReturnType): Observable<any> {
        const returnReq = {
            Url: credential.QueryPaymentStatusURL,
            XmlDoc: "<REQUEST>" +
                "<XWEBID>" + credential.XWEBID + "</XWEBID>" +
                "<XWEBTERMINALID>" + credential.XWEBTERMINALID + "</XWEBTERMINALID>" +
                "<XWEBAUTHKEY>" + credential.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
                "<TRANSACTIONTYPE>" + paymentReturnType +"</TRANSACTIONTYPE>" +
                "<AMOUNT>" + amount + "</AMOUNT>" +
                "<TRANSACTIONID>" + transId + "</TRANSACTIONID>" +
                "<RECEIPTFORMAT>TEXT</RECEIPTFORMAT>" +
                "</REQUEST>"
        }
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post<any>(
            `${this.baseURL}/Payment/GetCNPResponseURL`, returnReq, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetPaymentStatus(credential, transId, orderId): Observable<any> {
        const returnReq = {
            Url: credential.QueryPaymentStatusURL,
            XmlDoc: "<REQUEST>" +
                "<XWEBID>" + credential.XWEBID + "</XWEBID>" +
                "<XWEBTERMINALID>" + credential.XWEBTERMINALID + "</XWEBTERMINALID>" +
                "<XWEBAUTHKEY>" + credential.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
                "<TRANSACTIONTYPE>QUERYPAYMENT</TRANSACTIONTYPE>" +
                "<ORDERID>" + orderId + "</ORDERID>" +
                "</REQUEST>"
        }
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post<any>(
            `${this.baseURL}/Payment/GetCNPResponseURL`, returnReq, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    openEdgeCardType(cType) {
        if (cType) {
          cType = cType.replace(/\s/g, '');
          if (cType.toLowerCase() == 'americanexpress') {
            return ECreditCards.American_Express;
          } else if (cType.toLowerCase() == 'visa') {
            return ECreditCards.Visa;
          } else if (cType.toLowerCase() == 'mastercard') {
            return ECreditCards.Master_Card;
          } else if (cType.toLowerCase() == 'discover') {
            return ECreditCards.Discover;
          } else if (cType.toLowerCase() == 'diners') {
            return ECreditCards.Diners;
          } else if (cType.toLowerCase() == 'jcb') {
            return ECreditCards.JCB;
          } else {
            return ECreditCards.Visa;
          }
        } else {
          return ECreditCards.Visa;
        }
      }

    saveVerifoneUnSaved(model): Observable<any> { // For creating Dummy record on initally CC Transaction and get OrderID, Then after CCT
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/Payment/SaveVerifoneUnSaved`;

        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    saveAutopayForVendor(model): Observable<any> { // For creating Dummy record on initally CC Transaction and get OrderID, Then after CCT
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/Payment/SaveAutopayForVendor`;

        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetVerifoneUnSavedDate(verifoneRecordId): Observable<string> {
        return this.http.get<any[]>(
            `${this.baseURL}/Payment/GetVerifoneUnSavedDate?VerifoneRecordId=${verifoneRecordId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    return res.body as any;
                }),
                catchError(this.handleError)
            );
    }

    GetMaxBatchNo(): Observable<any> {
        return this.http.get<any[]>(
            `${this.baseURL}/Payment/GetMaxBatchNo`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    return res.body as any;
                }),
                catchError(this.handleError)
            );
    }

    replaceXMLEntity(stringValue) {
        if(stringValue && stringValue.trim().length) {
            if(stringValue.includes("&")) {
                stringValue = stringValue.replace(/[&,]/g, "&amp;");
            }
            if(stringValue.includes("<")) {
                stringValue = stringValue.replace("<", "&lt;")
            }
            if(stringValue.includes(">")) {
                stringValue = stringValue.replace(">", "&gt;")
            }
            if(stringValue.includes('"')) {
                stringValue = stringValue.replace('"', "&quot;")
            }
            if(stringValue.includes("'")) {
                stringValue = stringValue.replace("'", "&apos;")
            }
        }
        return stringValue;
    }

    
    getCNPService(rqsturl, request): Observable<any> {
        const tempobj = {
            Url: rqsturl,
            XmlDoc: request
        };
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post<any>(
            `${this.baseURL}/Payment/GetCNPResponseURL`, tempobj, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    cardOnFilePaymentEdge(model): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/Payment/EdgeExpressCCPayment`;

        return this.http.post<any>(url, model, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    emailPaymentReceiptForAutopay(emailReceiptData): Observable<any[]> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Payment/EmailPaymentReceiptForAutopay`;
        return this.http.post<any>(url, emailReceiptData, options)
          .pipe(
            map(res => {
              const response = res as any[];
              return response;
            }),
            catchError(this.handleError)
          );
      }

    getCNPResponse(credentials, orderId): Observable<any> {
        const tempobj = {
            Url: credentials.QueryPaymentStatusURL,
            XmlDoc: "<REQUEST>" +
                "<XWEBID>" + credentials.XWEBID + "</XWEBID>" +
                "<XWEBTERMINALID>" + credentials.XWEBTERMINALID + "</XWEBTERMINALID>" +
                "<XWEBAUTHKEY>" + credentials.XWEBAUTHKEY + "</XWEBAUTHKEY>" +
                "<TRANSACTIONTYPE>QUERYPAYMENT</TRANSACTIONTYPE> <ORDERID>" + orderId + "</ORDERID>" +
                "<RECEIPTFORMAT>TEXT</RECEIPTFORMAT>" +
                "</REQUEST> "
        };
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post<any>(
            `${this.baseURL}/Payment/GetCNPResponseURL`, tempobj, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getRCMServiceStatus(sessionId, orderId, isCancelRequest): Observable<any> {
        let url = '';
        if (isCancelRequest) {
            url = 'https://localsystem.paygateway.com:21113/RcmService.svc/Update?updateParameters=%2FCancelTransaction%20%2FSessionId%3A' + `${sessionId}`;
        } else {
            url = 'https://localsystem.paygateway.com:21113/RcmService.svc/status?SessionId=' + `${orderId}`;
        }

        return this.http.get<any>(
            `${url}`)
            .pipe(
                map(res => {
                    const responseRCM = res as any;
                    return responseRCM;
                }),
                catchError(this.handleError)
            );
    }

    GetTransactionVerifoneOGPayment(TransId): Observable<string> {
        return this.http.get<any[]>(
            `${this.baseURL}/AccountReceipt/GetTransactionVerifoneOGPayment?transId=${TransId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    return res.body as any;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            // this.appinsights.logEvent(errMessage);
            return throwError(errMessage);
        }
        return throwError(error || 'Node.js server error');
    }
}